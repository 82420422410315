<template>
  <header
    id="header"
    class="fixed top-0 left-0 right-0 w-full z-50 bg-blue-500 shadow-lg border-b border-blue-600 text-white"
  >
    <!-- TRANSPARENT BACKGROUND  -->

    <!-- <div
      id="account-background"
      class="absolute top-0 left-0 w-screen h-screen bg-black opacity-0"
      :class="{ hidden: !toggleAccountCheck }"
      
    ></div> -->
    <!--  -->
    <!-- MAIN HEADER -->
    <div class="flex flex-nowrap justify-between items-center py-1">
      <label
        for="menu-toggle"
        class="md:mx-5 p-3 hover:bg-blue-400 rounded-full cursor-pointer"
      >
        <svg
          class="fill-current text-white ml-0"
          viewBox="0 0 15 15"
          width="15"
          height="15"
        >
          <rect y="2" width="15" height="2"></rect>
          <rect y="7" width="15" height="2"></rect>
          <rect y="12" width="15" height="2"></rect>
        </svg>
      </label>
      <input
        type="checkbox"
        class="hidden"
        id="menu-toggle"
        @change="toggleDrawerMenu()"
        :checked="toggleDrawerCheck"
      />

      <div class="hidden md:block font-bold">
        <p class="whitespace-nowrap">{{ title }}</p>
      </div>

      <div class="flex-1 sm:mx-5 relative">
        <input
          id="search"
          class="hidden sm:block sm:w-4/5 text-sm input-with-icon"
          type="text"
          placeholder="Cerca prodotti e risorse"
          @click="closeAllDropdowns()"
        />
        <label for="search" class="hidden sm:block">
          <i class="mdi mdi-magnify w-3 h-3 absolute left-5 top-1"></i>
        </label>
      </div>
      <avatar :userData="userData"></avatar>
      <!-- <div class="hidden mx-5 md:block relative text-gray-900 z-50">
        <div>
          <label for="accountDropdown-toggle" class="cursor-pointer">
            <img
              src="https://picsum.photos/70"
              width="35"
              height="35"
              class="rounded-full"
              alt=""
            />
          </label>
          <input
            type="checkbox"
            class="hidden"
            id="accountDropdown-toggle"
            @change="toggleAccountDropdown()"
            :checked="toggleAccountCheck"
          />
        </div>
        <div
          class="absolute right-0 w-80 bg-white shadow-md text-xs"
          :class="{ hidden: !toggleAccountCheck }"
        >
          <div class="flex flex-row p-5 border-b-2 border-gray-200">
            <img
              src="https://picsum.photos/160"
              width="80"
              height="80"
              class="rounded-full"
              alt=""
            />
            <div class="flex-1 ml-5">
              <p class="font-bold">
                {{ getCurrentUserInfo.email }}
              </p>
            </div>
          </div>
          <div class="px-5 py-3 text-right bg-gray-100">
            <button class="btn" @click="signOut()">Esci</button>
          </div>
        </div>
      </div> -->
    </div>
  </header>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

import { mapActions, mapGetters } from 'vuex'
import Avatar from './Avatar.vue'

export default {
  components: { Avatar },
  data: () => ({
    mdiMagnifySVG: mdiMagnify
  }),
  name: 'Header',
  props: {
    title: {
      type: String,
      default: 'iTrinus Admin Panel'
    }
  },
  computed: {
    ...mapGetters('auth', ['userData']),
    drawer: {
      get() {
        return this.$store.getters['drawer/toggleIsChecked']
      },
      set(v) {
        console.log('CAMBIO DRAWER ', v)
        this.toggleCheckSwitch(v)
      }
    },
    searchText: {
      get() {
        return this.$store.getters['structures/searchText']
      },
      set(v) {
        //console.log("Store ",this.$store,v)
        this.$store.commit('structures/SET_SEARCHTEXT', v)
      }
    },
    getCurrentUserInfo: function() {
      return this.$store.getters['auth/currentUserInfo']
    },
    toggleDrawerCheck: function() {
      return this.$store.getters['drawer/toggleIsChecked']
    },
    toggleAccountCheck: function() {
      return this.$store.getters['header/toggleAccountIsChecked']
    }
  },
  methods: {
    ...mapActions('drawer', ['toggleCheckSwitch']),
    toggleDrawerMenu: function() {
      this.$store.commit('drawer/SET_TOGGLE', !this.toggleDrawerCheck)
    },
    // toggleOrganizationsMenu: function() {
    //     this.closeAllDropdowns('organizations');
    //     this.$store.commit('header/SET_TOGGLE_ORGANIZATIONS', !this.toggleOrganizationsCheck)
    // },
    toggleAccountDropdown: function() {
      this.closeAllDropdowns('account')
      this.$store.commit('header/SET_TOGGLE_ACCOUNT', !this.toggleAccountCheck)
    },
    signOut: function() {
      this.$store.dispatch('auth/signOut')
    },
    closeAccountDropdown: function() {
      this.$store.dispatch('header/toggleAccountCheckSwitch', false)
    },
    // closeOrganizationsDropdown: function () {
    //     this.$store.dispatch('header/toggleOrganizationsCheckSwitch', false)
    // },
    closeAllDropdowns: function(target) {
      this.$store.dispatch('header/closeAllDropdowns', {
        document: document,
        store: this.$store,
        target: target
      })
    }
  },
  async created() {
    //await this.$store.dispatch('organizations/queryOrganizations');
    console.log('Query ', this.$route.query.filter)
    this.searchText = this.$route.query.filter
  }
}
</script>
<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  margin-top: 6px;
}
</style>
