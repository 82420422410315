<template>
  <div>
    <!-- BLACK BACKGROUND  -->
    <dismissible v-if="toggleDrawer" @click="closeDrawer"></dismissible>
    <!--  -->
    <transition name="slide">
      <div
        id="drawer"
        class="z-50 fixed top-0 left-0 bottom-0 mt-12 bg-white overflow-y-auto shadow-2xl"
        :class="{ hidden: !toggleDrawer }"
        v-if="toggleDrawer"
      >
        <router-link v-for="m in menu" :to="m.route" :key="m.id">
          <div
            class="flex justify-between w-72 py-3 text-gray-800 hover:bg-gray-200"
            :class="{ 'text-blue-500': isCurrentPathActive(m.route) }"
            @click="closeDrawer"
          >
            <div class=" px-5">
              <i class="mdi mdi-18px w-5 h-5" :class="`${m.icon}`"></i>
            </div>
            <p class="flex-1 text-left">{{ m.title }}</p>
            <p class="text-right pl-5 pr-4">
              <i class="mdi mdi-chevron-right w-3 h-3 text-gray-600"></i>
            </p>
          </div>
        </router-link>
      </div>
    </transition>
  </div>
</template>
<script>
import { mdiChevronRight } from '@mdi/js'
import { mapGetters } from 'vuex'
import Dismissible from './_ui/Dismissible.vue'

export default {
  components: { Dismissible },
  name: 'Drawer',
  props: {
    // drawer: {
    //   type: Boolean,
    //   default: false,
    // }
  },
  data: () => ({
    mdiChevronRightSVG: mdiChevronRight
  }),
  computed: {
    ...mapGetters('drawer', ['menu']),
    toggleDrawer() {
      return this.$store.getters['drawer/toggleIsChecked']
    },
    drawer: {
      get() {
        return this.$store.getters['drawer/toggleIsChecked']
      },
      set(v) {
        this.$store.commit('drawer/SET_TOGGLE', v)
      }
    }
  },
  methods: {
    closeDrawer: function() {
      this.$store.dispatch('drawer/toggleCheckSwitch', false)
    },
    getComponent(c) {
      let comp = c.children ? 'v-list-group' : 'v-list-item'
      console.log('Component ', comp)
      return comp
    },
    isCurrentPathActive: function(routeToMatch) {
      const pathMatchResult = this.$router.currentRoute.matched
      if (pathMatchResult.length <= 0) return false
      return pathMatchResult[0].path.startsWith(routeToMatch)
    }
  }
}
</script>

<style>
@keyframes toggleMenu_open {
  from {
    left: -300px;
  }
  to {
    left: 0;
  }
}

#drawer {
  animation-name: toggleMenu_open;
  animation-duration: 0.3s;
}
.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
