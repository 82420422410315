<template>
  <div
    class="z-50 absolute top-0 left-0 w-screen h-screen bg-black opacity-30"
    @click="$emit('click')"
  ></div>
</template>

<script>
export default {
  name: 'Dismissible'
}
</script>

<style></style>
