<template>
  <div class="hidden mx-5 md:block text-gray-900">
    <!-- DISMISSABLE -->
    <dismissible @click="closeAllDropdowns" v-if="showMenu"></dismissible>
    <!-- PROFILE PICTURE / TRIGGER -->
    <div>
      <label
        for="accountDropdown-toggle"
        class="cursor-pointer"
        @click="toggleMenu"
      >
        <img
          src="https://picsum.photos/70"
          width="35"
          height="35"
          class="rounded-full"
          alt=""
        />
      </label>
    </div>
    <!-- MENU -->
    <transition name="slide">
      <div
        class="absolute right-4 top-12 w-80 bg-white shadow-md text-xs z-50"
        v-show="showMenu"
      >
        <div class="flex flex-row p-5 border-b-2 border-gray-200">
          <img
            src="https://picsum.photos/160"
            width="80"
            height="80"
            class="rounded-full"
            alt=""
          />
          <div class="flex-1 ml-5">
            <h1 class="font-bold text-xl">
              {{ userData.name }}
              {{ userData.family_name }}
            </h1>
            <h3 class="text-sm text-gray-600">
              {{ userData.email }}
            </h3>
          </div>
        </div>
        <div class="px-5 py-3 text-right bg-gray-100">
          <router-link class="btn" :to="`/logout`">Esci</router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Dismissible from '@/_components/_utils/Dismissible.vue'
export default {
  name: 'Avatar',
  props: {
    userData: {
      type: Object,
      default: () => {}
    }
  },
  components: { Dismissible },
  data: () => ({
    showMenu: false
  }),
  computed: {
    ...mapState('auth', ['currentUserData']),
    ...mapGetters({ toggleAccountCheck: 'header/toggleAccountIsChecked' })
  },
  methods: {
    signOut: function() {
      this.$store.dispatch('auth/signOut')
    },
    toggleMenu() {
      console.log('Menu Toggle ')
      this.showMenu = !this.showMenu
    },
    closeAllDropdowns: function() {
      console.log('Chiudi tutto')
      this.showMenu = false
      // this.$store.dispatch("header/closeAllDropdowns", {
      //   document: document,
      //   store: this.$store,
      //   target: target
      // });
    }
  }
}
</script>
<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.25s;
}
.slide-enter, .slide-leave-to /* .slide-leave-active below version 2.1.8 */ {
  transform: translateY(10%);
  opacity: 0;
}
</style>
